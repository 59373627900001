




































import { defineComponent } from '@nuxtjs/composition-api';
import SkeletonLoader from '~/components/SkeletonLoader/index.vue';
import ProductLayout from '~/modules/catalog/product/layouts/product.vue';
import ProductSection from '~/modules/catalog/product/components/ProductSection.vue';

export default defineComponent({
  name: 'ProductSkeleton',
  components: {
    ProductLayout,
    SkeletonLoader,
    ProductSection,
  },
});
