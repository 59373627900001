import { useRouter, useContext } from '@nuxtjs/composition-api';
import type { Product } from '~/modules/catalog/product/types';
import useCart from '~/modules/checkout/composables/useCart';
import { useProduct } from '~/modules/catalog/product/composables/useProduct';
import { Aggregation } from '~/modules/GraphQL/types';
import type { BundleProduct } from '~/modules/GraphQL/types';

export const useAddToCart = () => {
  const {
    addItem: addItemToCartBase,
    isInCart,
    error,
    loading,
    canAddToCart,
  } = useCart();
  const router = useRouter();
  const { app } = useContext();
  const { getProductPath } = useProduct();
  const addItemToCart = async (params: {
    product: Product,
    quantity: number,
    selectedOptions?: Record<string, { uid: string, quantity: number }>,
    productConfiguration?: Record<string, string>,
    attributes?: Aggregation[],
    position?: number
  }) => {
    const {
      product,
      quantity,
      productConfiguration,
      selectedOptions,
    } = params;
    // @ts-ignore
    // eslint-disable-next-line no-underscore-dangle
    const productType = product.__typename;

    switch (productType) {
      case 'SimpleProduct':
      case 'VirtualProduct':
        await addItemToCartBase({
          product,
          quantity,
        });
        break;
      case 'BundleProduct':
        if (!selectedOptions) break;
        const bundleProductData = Object.keys(selectedOptions).map((selectedOptionKey) => {
          const selectedOption = selectedOptions[selectedOptionKey];
          return {
            uid: selectedOption.uid,
            value: selectedOption.quantity,
          };
        });

        await addItemToCartBase({
          product: {
            ...product,
            bundle_options: bundleProductData,
          } as BundleProduct,
          quantity,
        });
        break;
      case 'ConfigurableProduct':
      case 'GroupedProduct':
        if (productConfiguration) {
          await addItemToCartBase({
            product,
            quantity,
            productConfiguration,
          });
        } else {
          const path = app.localeRoute(getProductPath(product));

          await router.push(path);
        }
        break;
      default:
        throw new Error(`Product Type ${productType} not supported in add to cart yet`);
    }
  };

  return {
    addItemToCart,
    isInCart,
    loading,
    error,
    canAddToCart,
  };
};
