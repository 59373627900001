export default `
  #graphql
  fragment ProductFields on ProductInterface {
    __typename
    id
    uid
    sku
    stock_status
    name
    new_from_date
    new_to_date
    product_withdrawn
    manufacturer
    bestseller
    special_price
    special_to_date
    special_from_date
    invoice_only
    estimated_avalibility
    delivery_time
    order_number
    doradca
    ean
    main_category
    categories {
      id
      name
      level
      path
    }
    productAttachments {
      type
      file
      url
      title
    }
    short_description {
      html
      __typename
    }
    description {
      html
    }
    agrofag
    grupa_upraw
    active_substance
    purpose
    dosage
    rodzaj_srodka_ochrony
    certyfikat_eko
    info_label

    price_tiers_with_customer_group {
      discount {
        amount_off
        percent_off
      }
      final_price {
        currency
        value
      }
      quantity
      customer_group_id
    }
    stock_item {
      qty_increments
      min_sale_qty
      max_sale_qty
    }
    thumbnail {
      url
      position
      disabled
      label
    }
    custom_attributes {
      attribute_metadata {
        code
        uid
      }
      entered_attribute_value {
        value
      }
      selected_attribute_options {
        attribute_option {
          is_default
          label
          uid
        }
      }
    }
  }
`;
