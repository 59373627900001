





















import {
  ref, computed, useContext, useRoute, defineComponent, useFetch, onMounted, watch,
} from '@nuxtjs/composition-api';
import { merge, omit } from 'lodash-es';
import { useCache, CacheTagPrefix } from '@vue-storefront/cache';
import { SfLoader } from '@storefront-ui/vue';
import { useProduct } from '~/modules/catalog/product/composables/useProduct';
import { ProductTypeEnum } from '~/modules/catalog/product/enums/ProductTypeEnum';
import { useWishlist, useApi } from '~/composables';
import type { Product } from '~/modules/catalog/product/types';
import type { ProductDetailsQuery } from '~/modules/GraphQL/types';
import ProductSkeleton from '~/modules/catalog/product/components/ProductSkeleton.vue';
import getProductPriceBySkuGql from '~/modules/GraphQL/customQueries/getProductPriceBySku.gql';
import { Aggregation } from '@vue-storefront/magento-types';
import { useOmnibusPrice } from '~/modules/catalog/product/composables/useOmnibusPrice';
import ProductBreadcrumbs from '~/modules/catalog/product/components/ProductBreadcrumbs.vue';
import { productToDLProduct } from '~/plugins/GoogleAnalitycs4/helpers';
import { useUser } from '~/modules/customer/composables/useUser';

export default defineComponent({
  name: 'ProductPage',
  components: {
    ProductBreadcrumbs,
    ProductSkeleton,
    SimpleProduct: () => import('~/modules/catalog/product/components/product-types/simple/SimpleProduct.vue'),
    ConfigurableProduct: () => import('~/modules/catalog/product/components/product-types/configurable/ConfigurableProduct.vue'),
    BundleProduct: () => import('~/modules/catalog/product/components/product-types/bundle/BundleProduct.vue'),
    SfLoader,
  },
  transition: 'fade',
  props: {
    routeData: {
      type: Object,
      default: null,
    },
  },
  setup({ routeData }) {
    const { query } = useApi();
    const product = ref<Product | null>(null);
    const omnibusPrices = ref({});
    const { addTags } = useCache();
    const route = useRoute();
    const { getProductDetails, loading, getProductAttributes } = useProduct();
    const { error: nuxtError } = useContext();
    const { load: loadWishlist } = useWishlist();
    const { getOmnibusPrices } = useOmnibusPrice();
    const { app } = useContext();
    const { isAuthenticated } = useUser();

    const attributeValues = ref<Aggregation[] | null>(null);

    const getBaseSearchQuery = () => ({
      filter: {
        sku: {
          eq: routeData.sku,
        },
      },
      configurations: Object.entries(route.value.query)
        .map((config) => config[1]) as string[],
      // TODO: Check this wishlist
      // .filter((config) => config[0] !== 'wishlist')
    });

    const renderer = computed(() => {
      // eslint-disable-next-line no-underscore-dangle
      if (product.value?.__typename === ProductTypeEnum.VIRTUAL_PRODUCT) {
        return ProductTypeEnum.SIMPLE_PRODUCT;
      }
      // eslint-disable-next-line no-underscore-dangle
      return product.value?.__typename ?? ProductTypeEnum.SIMPLE_PRODUCT;
    });

    const fetchProductExtendedAttributes = async (sku: string) => {
      attributeValues.value = await getProductAttributes(sku);
    };

    const fetchOmnibusData = async () => {
      omnibusPrices.value = await getOmnibusPrices([product.value], 'all');
    };

    const pushProductDetail = (productForDL: Product) => {
      const DLProduct = productToDLProduct({
        product: productForDL,
        variant: productForDL?.configurable_product_options_selection_all?.variant,
        optional: {
          list: 'Product Page',
          attributes: attributeValues.value,
        },
      });
      app.$googleTagManager.detail({ product: DLProduct });
    };

    const pushProductConfigurableDetail = (productForDL: Product) => {
      const DLProduct = productToDLProduct({
        product: productForDL,
        optional: {
          list: 'Product Page',
          attributes: attributeValues.value,
        },
      });
      app.$googleTagManager.configurable_detail({ product: DLProduct });
    };

    const fetchProductExtendedData = async (searchQuery = getBaseSearchQuery()) => {
      const { data } = await query<ProductDetailsQuery>(getProductPriceBySkuGql, searchQuery);
      const fieldsToOmit = ['configurable_product_options_selection_all'];
      const optimizedProduct = omit(product.value, fieldsToOmit);
      product.value = merge({}, optimizedProduct, data?.products?.items?.[0] as Product);
    };

    const fetchProductBaseData = async (searchQuery = getBaseSearchQuery()) => {
      const result = await getProductDetails({
        ...searchQuery,
      });
      product.value = merge({}, product.value, (result.items[0] as Product) ?? null);
    };

    const getProductConfigurationsBySku = (sku: string): string[] => {
      const configurations: string[] = [];
      if (!product.value || !sku) return configurations;
      const selectedVariant = product.value.variants?.find((variant) => variant?.product?.sku === sku);
      const configurationValueIndexList = selectedVariant?.attributes?.map((attribute) => attribute.value_index);
      product.value.configurable_options?.forEach((option) => option?.values?.forEach((value) => {
        if (configurationValueIndexList?.includes(value?.value_index)) {
          configurations.push(value?.uid);
        }
      }));
      return configurations;
    };

    useFetch(async () => {
      await fetchProductBaseData();
      if (routeData.sku) {
        await fetchProductExtendedAttributes(routeData.sku as string);
      }

      if (Boolean(product?.value?.sku) === false) {
        nuxtError({ statusCode: 404 });
      }

      const productConfiguration = ref(route.value.query);
      if (Object.entries(productConfiguration.value).length > 0) {
        const configurationFromQuery = Object.entries(productConfiguration.value).map((config) => config[1]) as string[];
        const configurations = getProductConfigurationsBySku(configurationFromQuery[0]);
        await fetchProductExtendedData({ filter: { sku: { eq: routeData.sku } }, configurations });
      }

      await fetchOmnibusData();

      if (!isAuthenticated.value) {
        const tags = [
          {
            prefix: CacheTagPrefix.View,
            value: `product-${product.value.uid}`,
          },
        ];

        const productTags = [
          {
            prefix: CacheTagPrefix.Product,
            value: product.value.uid,
          },
        ];

        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        addTags([...tags, ...productTags]);
      }
    });

    watch(product, (newValue) => {
      // eslint-disable-next-line no-underscore-dangle
      if (product.value?.__typename === ProductTypeEnum.CONFIGURABLE_PRODUCT
        && !product.value?.configurable_product_options_selection_all?.variant) {
        pushProductConfigurableDetail(newValue);
        return;
      }
      pushProductDetail(newValue);
    });

    onMounted(async () => {
      await Promise.all([loadWishlist()]);
    });

    return {
      renderer,
      loading,
      product,
      fetchProduct: fetchProductExtendedData,
      attributeValues,
    };
  },
});
