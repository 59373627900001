import { ref } from '@nuxtjs/composition-api';

export type ModalType = 'deliveryPopup' | 'paymentMethodsPopup' | 'pickuppoints';

export function useModals() {
  const isDeliveryPopup = ref(false);
  const isPickupPointPopup = ref(false);
  const isPaymentMethodsPopup = ref(false);

  const openModal = (modalName: ModalType) => {
    switch (modalName) {
      case 'deliveryPopup':
        isDeliveryPopup.value = true;
        break;
      case 'pickuppoints':
        isPickupPointPopup.value = true;
        break;
      case 'paymentMethodsPopup':
        isPaymentMethodsPopup.value = true;
        break;
      default:
        break;
    }
  };

  const closeModals = () => {
    isDeliveryPopup.value = false;
    isPaymentMethodsPopup.value = false;
    isPickupPointPopup.value = false;
  };

  return {
    isPickupPointPopup,
    isDeliveryPopup,
    isPaymentMethodsPopup,
    openModal,
    closeModals,
  };
}
