























































import {
  defineComponent, PropType, ref,
} from '@nuxtjs/composition-api';
import { addBasePath } from '~/helpers/addBasePath';
import { SfImage } from '@storefront-ui/vue';

export default defineComponent({
  name: 'ProductSection',
  components: {
    SfImage,
  },
  props: {
    title: {
      type: String as PropType<string>,
      required: true,
    },
    subTitle: {
      type: String as PropType<string>,
      default: '',
      required: false,
    },
    isInitialOpen: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
    id: {
      type: String as PropType<string>,
      default: '',
    },
  },
  setup({ isInitialOpen }) {
    const isOpen = ref(isInitialOpen);
    const content = ref<HTMLElement | null>(null);
    const getContentHeight = () => content.value?.scrollHeight;
    const toggleOpen = () => {
      isOpen.value = !isOpen.value;
    };

    return {
      isOpen,
      content,
      toggleOpen,
      addBasePath,
      getContentHeight,
    };
  },
});
