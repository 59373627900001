















import {
  computed, defineComponent, PropType, toRef,
} from '@nuxtjs/composition-api';
import { Product } from '~/modules/catalog/product/types';
import { getBreadcrumbs } from '~/modules/catalog/product/getters/productGetters';
import { SfBreadcrumbs } from '@storefront-ui/vue';

export default defineComponent({
  name: 'ProductBreadcrumbs',
  components: {
    SfBreadcrumbs,
  },
  props: {
    product: {
      type: [Object, null] as PropType<Product>,
      default: null,
    },
  },
  setup(props) {
    const product = toRef(props, 'product');

    const breadcrumbs = computed(() => {
      const productCategories = product.value?.categories ?? [];
      const mainCategory = product.value?.main_category ?? null;
      const category = mainCategory
        ? productCategories.find((cat) => cat.id === mainCategory)
        : (Array.isArray(productCategories) ? [...productCategories].pop() : null);
      return [
        {
          text: '⌂ Strona główna',
          link: '/',
        },
        ...getBreadcrumbs(product.value, category).map((breadcrumb) => ({
          ...breadcrumb,
          link: breadcrumb.link,
        })),
      ];
    });

    const getBreadcrumbsSchema = () => (JSON.stringify({
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: breadcrumbs.value.map((breadcrumb, index) => ({
        '@type': 'ListItem',
        position: index + 1,
        name: breadcrumb.text,
        item: `https://agrosimex.pl${breadcrumb.link}`,
      })),
    }));

    return {
      breadcrumbs,
      getBreadcrumbsSchema,
    };
  },
});
