







































































































































































import { SfLink } from '@storefront-ui/vue';
import { addBasePath } from '~/helpers/addBasePath';
import {
  computed, defineComponent, onMounted, ref, ssrRef, useFetch, nextTick, useMeta, useContext, useRoute,
} from '@nuxtjs/composition-api';
import { useApi } from '~/composables/useApi';
import { CacheTagPrefix, useCache } from '@vue-storefront/cache';
import type { CacheTag } from '@vue-storefront/cache';
import { usePageStore } from '~/stores/page';
import {
  useCategory, useFacet, useUiHelpers, useUiState, useProduct, useImage,
} from '~/composables';

import { useAddToCart } from '~/helpers/cart/addToCart';
import { useWishlist } from '~/modules/wishlist/composables/useWishlist';
import { useCategoryContent } from '~/modules/catalog/category/components/cms/useCategoryContent';
import { useTraverseCategory } from '~/modules/catalog/category/helpers/useTraverseCategory';
import facetGetters from '~/modules/catalog/category/getters/facetGetters';
import {
  getMetaInfo, getUrlMeta, getMagentoMeta, getPaginationMeta,
} from '~/helpers/getMetaInfo';
import type {
  ProductInterface, ProductsListQuery, Aggregation, Products,
} from '~/modules/GraphQL/types';
import type { SortingModel } from '~/modules/catalog/category/composables/useFacet/sortingOptions';
import type { Pagination } from '~/composables/types';
import type { Product } from '~/modules/catalog/product/types';
import { useOmnibusPrice } from '~/modules/catalog/product/composables/useOmnibusPrice';
import LazyHydrate from 'vue-lazy-hydration';
import { getCategoryImage } from '~/helpers/getCategoryImage';
import GetProductFilterByCategoryQuery from '~/modules/catalog/category/components/filters/command/getProductFilterByCategory.gql';
import { useUser } from '~/modules/customer/composables/useUser';

export default defineComponent({
  name: 'CategoryPage',
  components: {
    LazyHydrate,
    SfLink,
    CategoryPagination: () => import('~/modules/catalog/category/components/pagination/CategoryPagination.vue'),
    CategoryEmptyResults: () => import('~/modules/catalog/category/components/CategoryEmptyResults.vue'),
    CategoryFilters: () => import('~/modules/catalog/category/components/filters/CategoryFilters.vue'),
    CmsContent: () => import('~/modules/catalog/category/components/cms/CmsContent.vue'),
    CategoryProductGrid: () => import('~/modules/catalog/category/components/views/CategoryProductGrid.vue'),
    CategoryNavbar: () => import('~/modules/catalog/category/components/navbar/CategoryNavbar.vue'),
    CategoryBreadcrumbs: () => import('~/modules/catalog/category/components/breadcrumbs/CategoryBreadcrumbs.vue'),
    SkeletonLoader: () => import('~/components/SkeletonLoader/index.vue'),
    ImageWrapper: () => import('~/components/image/ImageWrapper.vue'),
    Brands: () => import('~/components/Brands/Brands.vue'),
  },
  transition: 'fade',
  props: {
    routeData: {
      type: Object,
      default: null,
    },
  },
  setup({ routeData }) {
    const route = useRoute();
    const { getContentData } = useCategoryContent();
    const { loadCategoryMeta } = useCategory();
    const { addTags } = useCache();
    const uiHelpers = useUiHelpers();
    const { isAuthenticated } = useUser();
    const { getOmnibusPrices } = useOmnibusPrice();
    const cmsContent = ref('');
    const isShowCms = ref(false);
    const isShowProducts = ssrRef(false);
    const products = ssrRef<ProductInterface[]>([]);
    const filters = ssrRef<Aggregation[]>([]);
    const promoProducts = ssrRef<ProductsListQuery['products']['items']>([]);
    const sortBy = ref<SortingModel>({ selected: '', options: [] });
    const pagination = ref<Pagination>({
      currentPage: 0,
      totalPages: 0,
    });
    const { env } = useContext();
    const CATEGORY_LANDING_PAGES = new Set([env.VSF_MAGENTO_BRANDS_CATEGORY_UID]);
    const queryPage = () => Number(uiHelpers.resolveQuery()?.page ?? 1);

    const productContainerElement = ref<HTMLElement | null>(null);

    const { getProductListMini } = useProduct();

    const {
      toggleFilterSidebar, toggleSortSidebar, changeToCategoryListView, changeToCategoryGridView, isFilterSidebarOpen, isSortSidebarOpen,
    } = useUiState();
    const { load: loadWishlist, addOrRemoveItem } = useWishlist();
    const { result, search } = useFacet();
    const { addItemToCart } = useAddToCart();
    const { imageSizes, baseImageConfig, getMagentoImage } = useImage();

    const categoryMeta = ref(null);

    const metaInfo = () => {
      const urlMeta = getUrlMeta(uiHelpers.resolvePath());
      const paginationMeta = getPaginationMeta({ pagination: pagination.value, route: queryPage(), baseData: urlMeta });
      return getMagentoMeta({ data: categoryMeta.value, baseData: paginationMeta });
    };

    useMeta(() => getMetaInfo({ page: metaInfo() }));

    const addItemToWishlist = async (product: Product) => addOrRemoveItem({ product, list: 'Category' });

    const {
      activeCategory, activeMainCategory, loadCategoryTree, loadCategoryTreeLanding,
    } = useTraverseCategory();
    const activeCategoryName = computed(() => activeCategory.value?.name ?? '');
    const activeCategoryBanner = computed(() => activeCategory.value?.image ?? '');
    const activeCategoryMobileBanner = computed(() => activeCategory.value?.mobile_banner_image ?? '');
    const activeCategoryLink = computed(() => activeCategory.value?.category_banner_link ?? '');
    const popularProductsSku = computed(() => activeCategory.value?.category_popular_sku?.split(',').map((sku) => sku.trim()) ?? []);
    const isBrandsPage = computed(() => activeCategory.value?.uid === env.VSF_MAGENTO_BRANDS_CATEGORY_UID);

    const categoryUid = routeData.uid;
    const { query } = useApi();

    const { fetch } = useFetch(async () => {
      promoProducts.value = [];
      if (popularProductsSku.value && popularProductsSku.value.length > 0) {
        const productListMini = await getProductListMini({
          pageSize: 18,
          filter: {
            sku: {
              in: popularProductsSku.value,
            },
          },
        });
        promoProducts.value = productListMini.items ?? [];
      }

      const shouldLoadFiltersAndSorting = !CATEGORY_LANDING_PAGES.has(activeCategory.value?.uid);

      const [content, categoryMetaData] = await Promise.all([
        getContentData(categoryUid as string),
        loadCategoryMeta({ category_uid: categoryUid }),
        shouldLoadFiltersAndSorting && search({ ...uiHelpers.getFacetsFromURL(), category_uid: categoryUid }),
      ]);

      if (shouldLoadFiltersAndSorting) {
        const searchVars = {
          filter: {
            category_uid: { eq: routeData.uid },
          },
          search: route.value?.query?.search || '',
        };
        const { data } = await query<{ products: Products }>(GetProductFilterByCategoryQuery, searchVars);
        filters.value = data?.products?.aggregations ?? [];
      }

      categoryMeta.value = categoryMetaData;
      cmsContent.value = content?.cmsBlock?.content ?? '';
      isShowCms.value = content.isShowCms;
      isShowProducts.value = content.isShowProducts;
      if (shouldLoadFiltersAndSorting) {
        products.value = facetGetters.getProducts(result.value) ?? [];
        sortBy.value = facetGetters.getSortOptions(result.value);
        pagination.value = facetGetters.getPagination(result.value);
      }

      await getOmnibusPrices(products.value as Product[]);

      if (activeMainCategory.value?.uid !== env.VSF_MAGENTO_MAIN_CATEGORY_UID) {
        await (CATEGORY_LANDING_PAGES.has(activeMainCategory.value?.uid)
          ? loadCategoryTreeLanding(activeMainCategory.value?.uid) : loadCategoryTree(activeMainCategory.value?.uid));
      }

      if (!isAuthenticated.value) {
        const tags = [{ prefix: CacheTagPrefix.View, value: routeData.uid }];
        let productTags: CacheTag[] = [];
        if (shouldLoadFiltersAndSorting) {
          productTags = products.value.map((product) => ({
            prefix: CacheTagPrefix.Product,
            value: product.uid,
          }));
        }
        addTags([...tags, ...productTags]);
      }
    });

    onMounted(() => {
      loadWishlist();
    });

    const goToPage = (page: number) => {
      uiHelpers.changePage(page, false);
      fetch();

      nextTick(() => {
        productContainerElement.value?.scrollIntoView({ block: 'start' });
        window.scrollBy(0, -200);
      });
    };

    const onReloadProducts = () => {
      goToPage(0);
    };

    const handleBannerClick = () => {
      if (!activeCategoryLink.value) return;
      window.open(activeCategoryLink.value as string, '_blank');
    };

    return {
      ...uiHelpers,
      toggleFilterSidebar,
      toggleSortSidebar,
      changeToCategoryListView,
      changeToCategoryGridView,
      isFilterSidebarOpen,
      isSortSidebarOpen,
      addItemToCart,
      addItemToWishlist,
      pagination,
      products,
      filters,
      promoProducts,
      sortBy,
      isShowCms,
      isShowProducts,
      cmsContent,
      activeCategoryName,
      activeCategoryBanner,
      activeCategoryMobileBanner,
      activeCategoryLink,
      productContainerElement,
      categoryMeta,
      onReloadProducts,
      goToPage,
      addBasePath,
      queryPage,
      getCategoryImage,
      imageSizes,
      baseImageConfig,
      isBrandsPage,
      getMagentoImage,
      activeCategory,
      handleBannerClick,
    };
  },
  head: {},
});
