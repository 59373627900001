











import CATEGORY from '~/modules/catalog/pages/category.vue';
import PRODUCT from '~/modules/catalog/pages/product.vue';

export default {
  name: 'PageResolver',
  components: {
    CATEGORY,
    PRODUCT,
  },
  layout: 'basic',
  async asyncData(context) {
    if (context.app.routeData) {
      return { routeData: context.app.routeData }
    }
    return { routeData: null }
  },
  middleware: [
    'url-resolver',
  ],
};
