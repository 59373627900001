



























import {
  defineComponent,
  computed,
} from '@nuxtjs/composition-api';
import { useModals } from '~/modules/catalog/product/composables/useModals';

export default defineComponent({
  name: 'ProductLayout',
  setup() {
    const { isPaymentMethodsPopup, isDeliveryPopup } = useModals();
    const isPopup = computed(() => isDeliveryPopup.value || isPaymentMethodsPopup.value);
    
    return { isPopup };
  },
});
