import fragmentPriceRangeFields from '~/modules/GraphQL/customQueries/fragments/fragmentPriceRangeFields';
import fragmentProductFields from '~/modules/GraphQL/customQueries/fragments/fragmentProductFields';
import fragmentProductCompositionFields from '~/modules/GraphQL/customQueries/fragments/fragmentProductCompositionFields';
import fragmentProductAdditionalFields from '~/modules/GraphQL/customQueries/fragments/fragmentProductAdditionalFields';

export default `
  #graphql
  query getProductPriceBySku(
    $filter: ProductAttributeFilterInput,
    $configurations: [ID!]
  ) {
    products(filter: $filter) {
      items {
        ...ProductFields
        ...ProductCompositionFields
        price_range {
          ...PriceRangeFields
        }

        ... on ConfigurableProduct {
          price_range {
            ...PriceRangeFields
          }

          configurable_product_options_selection_all(configurableOptionValueUids: $configurations) {
            options_available_for_selection {
              attribute_code
              option_value_uids
            }
            media_gallery {
              disabled
              label
              position
              url
            }
            variant {
              ...ProductFields
              ...ProductCompositionFields
              ...ProductAdditionalFields
              price_range {
                ...PriceRangeFields
              }
            }
          }
        }

        ... on BundleProduct {
          dynamic_sku
          items {
            position
            __typename
            required
            sku
            title
            type
            uid
            options {
                uid
                quantity
                position
                is_default
                price
                price_type
                can_change_quantity
                label
                product {
                  active_substance
                  agrofag
                  bestseller
                  categories {
                    id
                    name
                    level
                  }
                  delivery_time
                  doradca
                  dosage
                  ean
                  estimated_avalibility
                  grupa_upraw
                  id
                  invoice_only
                  manufacturer
                  name
                  new_from_date
                  new_to_date
                  order_number
                  price_range {
                    ...PriceRangeFields
                  }
                  price_tiers_with_customer_group {
                    discount {
                      amount_off
                      percent_off
                    }
                    final_price {
                      currency
                      value
                    }
                    quantity
                    customer_group_id
                  }
                  productAttachments {
                    file
                    title
                    type
                    url
                  }
                  product_withdrawn
                  purpose
                  short_description {
                    html
                    __typename
                  }
                  sku
                  stock_item {
                    max_sale_qty
                    min_sale_qty
                    qty_increments
                  }
                  stock_status
                  thumbnail {
                    disabled
                    label
                    position
                    url
                  }
                  uid
                }
              }
          }
        }
      }
    }
  }
  ${fragmentPriceRangeFields}
  ${fragmentProductFields}
  ${fragmentProductCompositionFields}
  ${fragmentProductAdditionalFields}
`;
